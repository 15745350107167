import React from 'react'
import {InlineImage} from 'react-tinacms-inline'
import Img from "gatsby-image"

const CustomInlineImage = ({ name, image, allImages }) => {
    if(process.env.EDITABLE === 'true'){
        return (
            <InlineImage
                name={name}
                parse={media => {
                    if (!media) return ''
                    const path = media.directory.split('/content/')[1]
                    return `../${path}${media.filename}`
                }}
                uploadDir={() => `/content/assets/`}
                previewSrc={(src, path, formValues) => {
                    const edge = allImages.edges.find((element) => {
                        return (element.node.fluid.src.split('/').pop() === src.replace(/^.*[\\\/]/, ''));
                    })
                    if (edge) {
                        return edge.node.fluid.src
                    }
                    return ''
                }}
            />
        )
    } else {
        return (
            <Img
                fluid={image.childImageSharp.fluid}
            />
        )
    }
}

export default CustomInlineImage