
const fields = [
    { name: "type", label: "Section Type", component: "select", options: ["basic", "testimony"] },
    { name: "contentPosition", label: "Content Position", component: "select", options: ["left", "right"] },
    {
        name: "backgroundColor", label: "Background Color", component: "color", options: [
            {
                value: '#ffffff',
                label: 'White',
            },
            {
                value: '#f9f9ff',
                label: 'Grey',
            },
        ],
    }
]

export const SectionGroupList = (data) => {

    return {
        name: 'rawJson.sections',
        label: 'Sections',
        component: 'group-list',
        itemProps: item => ({
            key: item.id,
            label: item.name,
        }),
        defaultItem: () => ({
            name: 'New Section',
            id: Math.random()
                .toString(36)
                .substr(2, 9),
            ...fields.reduce((prev, item, index) => {
                prev[item.name] = ""
                return prev
            }, {}),
        }),
        fields: fields,
    }
}
