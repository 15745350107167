import React, { useEffect } from "react"
import LogoImg from './../../content/assets/intelius-logo.png'
import { useModalContext, useModalContextToggle } from './modalContext'

const Modal = ({ isShowing, hide }) => {
    const isModalOpen = useModalContext()
    const toggleModal = useModalContextToggle()


    function handleTransitionEnd(ev) {
        if(!isModalOpen) {
            document.getElementById("signup-modal").style.display = "none"
            document.documentElement.classList.remove('uk-modal-page')
            document.body.style.overflowY="auto"
        }
    }

    return (
        <div onTransitionEnd={handleTransitionEnd} id="signup-modal" className={"uk-modal-full uk-modal"}>
            <div className="uk-modal-dialog">
                <button onClick={toggleModal} className="uk-modal-close-full uk-close-large" type="button">
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="close-large"><line fill="none" stroke="#000" strokeWidth="1.4" x1="1" y1="1" x2="19" y2="19"></line><line fill="none" stroke="#000" strokeWidth="1.4" x1="19" y1="1" x2="1" y2="19"></line></svg>
                </button>
                <div className="uk-grid-collapse uk-child-width-1-2@l uk-flex-middle uk-grid" style={{ background: '#fbfbff' }} style={{ minHeight: 'calc(100vh)' }}>
                    <div className="uk-background-cover uk-visible@l uk-first-column" style={{ minHeight: 'calc(100vh)' }}>
                        <img src={LogoImg} alt="Intelius" style={{ maxWidth: 300 }} /></div>
                    <div className="uk-padding-large" style={{ background: '#ffffff' }}>
                        <div id="signupForm"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal