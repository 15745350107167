import React, { useState } from "react"
import Header from './header'
import Footer from './footer'
import Modal from './modal'
import useScript from './../hooks/useScript'
import { ModalProvider, useModalContextToggle } from './modalContext'

import './../app.less'
import './../mixins.less'

const PagesLayout = ({ children }) => {

    useScript('//js.hsforms.net/forms/shell.js');
    
    return (
        <>
            <Modal/>
            <Header/>
            <>{children}</>
            <Footer/>
        </>
    )
}

export default PagesLayout