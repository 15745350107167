import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import Section from '../components/section'
import SectionTestimonial from '../components/sectionTestimonial'
import PagesLayout from '../components/pagesLayout'
import CustomInlineImage from '../components/customInlineImage'


import TrophyImg from './../../content/assets/trophy.svg'

import { useModalContextToggle } from './../components/modalContext'

import { useJsonForm } from 'gatsby-tinacms-json'
import { usePlugin } from 'tinacms'

import { SectionGroupList } from '../group-lists/section'
import { InlineForm, InlineTextarea } from 'react-tinacms-inline'


const Home = (props) => {

    const toggleModal = useModalContextToggle()

    const propsData = props.data.page
    const images = props.data.images
    const formConfig = {
        id: 1212,
        label: 'Edit Home Page',
        fields: [
            {
                label: 'Hero Image',
                name: 'rawJson.hero_image',
                component: 'image',
                parse: media => {
                    if (!media) return ''
                    const path = media.directory.split('/content/')[1]
                    return `../${path}${media.filename}`
                },
                uploadDir: () => `/content/assets/`,
                previewSrc: (src, path, formValues) => {
                    const edge = images.edges.find((element) => {
                        return (element.node.fluid.src.split('/').pop() === src.replace(/^.*[\\\/]/, ''));
                    })
                    if(edge) {
                        return edge.node.fluid.src
                    }
                    return ''
                },
            },
            SectionGroupList(propsData)

        ],
        initialValues: propsData,
        onSubmit: async (formData) => {
            // push to github here to save changes
        },
    }

    const [data, form] = useJsonForm(propsData, formConfig)
    usePlugin(form)
    //<Img id="hero_image" fluid={data.hero_image.childImageSharp.fluid}/>
    return (
        <PagesLayout>
            <InlineForm form={form}>
                <div className="hero"  style={{backgroundImage: `url('${data.hero_image.childImageSharp.fluid.src}')`}}>
                    <div className="hero-bg"></div>
                    <div className="banner">
                        <div className="uk-container uk-grid align-center">
                            <div className="uk-width-1-2@m uk-animation-slide-top-small" style={{ position: 'relative' }}>
                                <p className="subhead"><img src={TrophyImg} /> <InlineTextarea name="rawJson.hero_headline" /></p>
                                <h1><InlineTextarea name="rawJson.hero_title" /></h1>
                                <p><InlineTextarea name="rawJson.hero_content" /></p>
                                <div onClick={toggleModal} className="uk-button uk-button-primary uk-align-left" uk-toggle="#signup-modal">Sign Up as an Affiliate</div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <CustomInlineImage image={data.hero_front_image} name="rawJson.hero_front_image" allImages={images} />
                            </div>
                        </div>
                    </div>
                    <div className="divider-shape-container">
                        <div className="divider-shape"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" className="shape-waves" style={{ left: 0, transform: 'rotate3d(0,1,0,180deg)' }}><path className="shape-fill shape-fill-1" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path></svg></div>
                    </div>
                </div>
                {
                    data.sections.map((item, index) => {
                        if (item.type == "testimonial") {
                            return <SectionTestimonial key={index} data={data.testimonials} />
                        }
                        return <Section key={index} data={item} index={index} form={form} allImages={images} />
                    })
                }
                <div className="section section-content-left green">
                    <div className="uk-container uk-grid align-center uk-scrollspy-inview uk-animation-slide-top-small" uk-scrollspy="cls:uk-animation-slide-top-small">
                        <h2>{data.affiliateTestimony.title}</h2>
                        <blockquote>{data.affiliateTestimony.content}</blockquote>
                        <p> – {data.affiliateTestimony.author}</p>
                    </div>
                </div>
            </InlineForm>
        </PagesLayout>
    )
}

export default Home

export const pageQuery = graphql`
query {
    site {
    siteMetadata {
        title
        }
    }
    images: allImageSharp {
        edges {
            node {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
    page: dataJson(path: {eq: "/home" }) {
        title
        hero_title
        hero_content
        hero_headline
        hero_image {
            relativePath
            childImageSharp {
                fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hero_front_image {
            relativePath
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        affiliateTestimony {
            title
            author
            content
        }
        testimonials {
            author
            content
        }
        sections {
            headline
            name
            content
            image {
                relativePath
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            type
            backgroundColor
            contentPosition
        }
      rawJson
      fileRelativePath
    }
}
`