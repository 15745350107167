import React from "react"
import { useModalContextToggle} from './modalContext'

import CustomInlineImage from './customInlineImage'
import { InlineForm, InlineTextarea } from 'react-tinacms-inline'

const Section = ({data, id, form, index, allImages}) => {

    const toggleModal = useModalContextToggle()

    //<Image imgsrc={`${data.image}`} className="uk-margin-medium-top uk-margin-medium-bottom"/>
    
    const textContent = (
        <div key="2" className="uk-width-1-2@m" uk-scrollspy="cls:uk-animation-slide-right-small">
            <p className="headline"><InlineTextarea name={`rawJson.sections[${index}].headline`} /></p>
            <h2><InlineTextarea name={`rawJson.sections[${index}].name`} /></h2>
            <p><InlineTextarea name={`rawJson.sections[${index}].content`} /></p>
            <div onClick={toggleModal} className="button uk-button uk-button-primary" uk-toggle="#signup-modal">Sign up as an Affiliate</div>
        </div>
    )
    const imageContent = (
        <div key="1" className="uk-width-1-2@m uk-visible@m" uk-scrollspy="cls:uk-animation-slide-left-small">
            <CustomInlineImage image={data.image} name={`rawJson.sections[${index}].image`} allImages={allImages} className="uk-margin-medium-top uk-margin-medium-bottom"/>
	  	</div>
    )
    let content = [textContent, imageContent];
    if(data.contentPosition == "right") {
        content = [imageContent,textContent];
    }
    const styles = {}
    if(data.backgroundColor) {
        styles.backgroundColor = data.backgroundColor
    }
    
    return (
        <InlineForm form={form}>
            <div key={id} style={styles} className={`section section-content-${data.contentPosition}`}>
                <div className="uk-container uk-grid align-center">
                    {content}
                </div>
            </div>
        </InlineForm>
    )
}

export default Section

